@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: normal;
  src: local('Inter'), url(./fonts/Inter-VariableFont_slnt,wght.ttf) format('woff');
}



body {
  margin: 0;
  overflow-x: hidden;
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex-height {
  max-height: 100%;
}
.flex-width {
  max-height: 100%;
}

.left-badge {
  border-left: 4px solid #e9383a;
  padding-left: 4px;
}


.text-cm-red {
  color: #e9383a;
}

.bg-cm-red {
  background: #e9383a;
}

h6 {
  font-weight: bold;
  color: #06065a;
}
.text-cm-blue {
  color: #06065a;
}

.bg-cm-blue {
  background: #06065a;
}

.highlight {
  position: relative;
  top: 0;
  transition: top ease 0.5s;
}

.highlight:hover {


}
